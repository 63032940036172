import { find, keyBy } from 'lodash'
import { stringify } from 'qs'
import { useMemo } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { usePageSettings } from 'hooks/usePageSettings'
import { convertDataQueryParamsToLegacyParams } from 'utils/converter'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { isEmpty } from 'utils'
import { DEFAULT_OMIT_FORECAST_CHART, useChartQueryModifier } from 'hooks/useChartQueryModifier'
import { useItemEntities } from './useItemEntities'

const useOfferingsTable = (options) => {
  const opts = {
    enabled: true,
    ordering: null,
    pagination: null,
    ...options
  }

  const { fetch, token } = useQueryFetcher()
  const { settings: filters } = usePageSettings()
  const selectedCustomer = useSelectedCustomer()

  const whereParams = useChartQueryModifier(DEFAULT_OMIT_FORECAST_CHART)

  // useChartQueryModifier doesn't include the sales_location filter, if all or the only available location is selected.
  // But this leads to issues on the offering pages, see https://linear.app/delicious-data/issue/TEC-2638/inconsistent-offerings-in-the-offering-grid-vs-table-views
  // So if we don't have a filter with field sales_location, we add it
  if (!whereParams.some((p) => p.field === 'sales_location')) {
    whereParams.push({
      field: 'sales_location',
      operator: '=',
      value: filters.location
    })
  }

  const converted = whereParams
    ? convertDataQueryParamsToLegacyParams(filters, whereParams, null, null, true, true, {
        menuline: 'menuline_item__name',
        component: 'component_obj__name',
        name: 'item__name'
      })
    : null
  const baseFilters = converted ? JSON.parse(converted.combine_filters) : {}
  const isEnabled = opts.enabled && whereParams != null && selectedCustomer != null

  const ordering = opts.ordering ? opts.ordering : converted.ordering
  const pagination = {
    page: opts.pagination ? opts.pagination.current || 1 : 1,
    page_size: opts.pagination ? opts.pagination.pageSize || 25 : 25
  }

  const queryKey = ['offerings-table', selectedCustomer, ordering, pagination, baseFilters, converted ? converted.search : null]

  const { data, status, error, isFetching } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/productions/?${stringify({
          ...converted,
          ordering,
          ...pagination,
          customer: selectedCustomer
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    }),
    placeholderData: keepPreviousData,
    enabled: isEnabled,
    retry: false,
    staleTime: 300000
  })

  const { data: itemsData } = useItemEntities({
    enabled: data != null,
    ids: data ? data.results.map(i => i.item) : []
  })

  const extendedResults = useMemo(() => {
    if (data && itemsData != null) {
      return data.results.map((i) => {
        const item = find(itemsData, { id: i.item })
        return {
          ...i,
          name: item ? isEmpty(item.remote_pk) ? item.name : `${item.name} (${item.remote_pk})` : null,
          description: item ? item.description : null
        }
      })
    }
  }, [data, itemsData])

  const keyedItems = useMemo(() => keyBy(itemsData, 'id'), [itemsData])

  return {
    data: extendedResults
      ? {
          ...data,
          results: extendedResults
        }
      : null,
    items: keyedItems,
    status,
    error,
    isFetching
  }
}

export default useOfferingsTable
