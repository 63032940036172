import classNames from 'classnames'
import { formatWith, getFormatterForType } from 'utils/formatter'
import { useRegionFormatPreset } from 'hooks/useRegionFormatPreset'
import { useIntl } from 'react-intl'
import { Text } from './Text'

export const InfoCard = ({ title, titleMessage, value, className, type, color }) => {
  const intl = useIntl()
  const regionPreset = useRegionFormatPreset()

  const formatted = formatWith(value, getFormatterForType(type), intl, regionPreset)

  return (
    <div className={classNames('bg-gray-lighter m-3 p-3 rounded-xl w-full', className)}>
      <Text size='l' type='display' color={color}>{formatted}</Text>
      <br />
      <Text size='s' className='select-none' color={color}>{titleMessage ? intl.formatMessage(titleMessage) : title}</Text>
    </div>
  )
}

export default InfoCard
