import { useQuery } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'

export const useOrderDecisionLog = (options) => {
  const opts = {
    enabled: true,
    id: null,
    ...options
  }

  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const { data, status } = useQuery({
    queryKey: ['order-decision-log', opts.id],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/orders/${opts.id}/decision-log/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => {
            if (err.detail && err.detail.toLowerCase().includes('not found')) {
              resolve({})
              return
            }
            reject(err)
          }
        }
      )
    }),
    enabled: opts.enabled && opts.id != null,
    staleTime: 0,
    cacheTime: 0
  })

  return { data, status }
}
