import { OPERATOR } from 'constants/index'
import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'
import { useIntl } from 'react-intl'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useRole } from '..'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { qFetchCustomers } from 'utils/queries'

export const useCustomers = (options) => {
  const intl = useIntl()
  const { role } = useRole()
  const opts = {
    enabled: true,
    force: false,
    ...options
  }
  const { fetch } = useQueryFetcher()
  const allCustomers = useGeneralStore((state) => state.allCustomers)
  const setAllCustomers = useGeneralStore((state) => state.setAllCustomers)
  const customersStore = useGeneralStore((state) => state.customersStore)

  const isEnabled = opts.force || (role === OPERATOR && opts.enabled)

  const { data, error, isFetching, isLoading } = useQuery({
    queryKey: ['customers'],
    queryFn: () => qFetchCustomers(fetch),
    placeholderData: keepPreviousData,
    enabled: isEnabled,
    retry: false,
    staleTime: 300000
  })

  // Filter out Smoke Test customers and memoize the result
  const filteredData = useMemo(() => {
    if (!data) return null
    return data.filter(customer => !customer.name.includes('Smoke Test'))
  }, [data])

  useEffect(() => {
    if (filteredData) {
      if (!isEqual(filteredData, allCustomers)) {
        setAllCustomers(filteredData)
        if (customersStore) {
          customersStore.setEntries({ data: filteredData, timestamp: Date.now() })
        }
      }
    }
  }, [filteredData])

  useEffect(() => {
    if (error) {
      message.error(intl.formatMessage(notifyMessages.fetchError, { type: 'customers', message: error.error ? error.error.message : error }), 15)
    }
  }, [error])

  return { data: filteredData, isLoading, error, isFetching }
}
