// Map ISO codes to specific presets (Format Families)
const REGION_TO_PRESET_MAPPING = [
  {
    isoCodes: ['de-DE', 'de-AT', 'de-LU'], // German speaking: 123.456,78 €
    preset: {
      currencySymbol: '€',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['de-CH', 'fr-CH', 'it-CH', 'li-LI'], // Swiss regions and Liechtenstein: 123'456,78 CHF
    preset: {
      currencySymbol: 'CHF',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '\''
    }
  },
  {
    isoCodes: ['nl-NL', 'nl-BE', 'fr-BE'], // Netherlands & Belgium: € 123.456,78
    preset: {
      currencySymbol: '€',
      currencyPosition: 'prefix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['sv-SE'], // Sweden: 123 456,78 SEK
    preset: {
      currencySymbol: 'SEK',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  },
  {
    isoCodes: ['da-DK'], // Denmark: 123.456,78 DKK
    preset: {
      currencySymbol: 'DKK',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['no-NO'], // Norway: 123 456,78 NOK
    preset: {
      currencySymbol: 'NOK',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  },
  {
    isoCodes: ['bg-BG'], // Bulgaria: 123 456,78 лв
    preset: {
      currencySymbol: 'лв',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  },
  {
    isoCodes: ['ro-RO'], // Romania: 123.456,78 lei
    preset: {
      currencySymbol: 'lei',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['el-GR', 'el-CY'], // Greece & Cyprus: 123.456,78 €
    preset: {
      currencySymbol: '€',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['cs-CZ'], // Czech Republic: 123 456,78 Kč
    preset: {
      currencySymbol: 'Kč',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  },
  {
    isoCodes: ['hu-HU'], // Hungary: 123 456,78 Ft
    preset: {
      currencySymbol: 'Ft',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  },
  {
    isoCodes: ['is-IS'], // Iceland: 123.456,78 ISK
    preset: {
      currencySymbol: 'ISK',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['fr-FR', 'es-ES', 'pt-PT', 'it-IT', 'fi-FI', 'lt-LT', 'lv-LV', 'ee-EE'], // Euro zone countries with space as thousand separator
    preset: {
      currencySymbol: '€',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  },
  {
    isoCodes: ['pl-PL'], // Poland: 123 456,78 zł
    preset: {
      currencySymbol: 'zł',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  },
  {
    isoCodes: ['hr-HR', 'sk-SK', 'sl-SI'], // Euro zone countries with dot as thousand separator
    preset: {
      currencySymbol: '€',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['ad-AD', 'mc-MC', 'sm-SM', 'va-VA'], // Additional Euro countries
    preset: {
      currencySymbol: '€',
      currencyPosition: 'suffix',
      currencySeparator: ' ',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  },
  {
    isoCodes: ['en-GB'], // United Kingdom: £123,456.78
    preset: {
      currencySymbol: '£',
      currencyPosition: 'prefix',
      currencySeparator: '',
      decimalSeparator: '.',
      thousandSeparator: ','
    }
  },
  {
    isoCodes: ['en-IE', 'mt-MT'], // Ireland & Malta: €123,456.78
    preset: {
      currencySymbol: '€',
      currencyPosition: 'prefix',
      currencySeparator: '',
      decimalSeparator: '.',
      thousandSeparator: ','
    }
  }
]

// Generate REGION_PRESETS by looping through mapping
const REGION_PRESETS = REGION_TO_PRESET_MAPPING.reduce((presets, entry) => {
  const { isoCodes, preset } = entry
  isoCodes.forEach((code) => {
    presets[code] = preset
  })
  return presets
}, {})

const ISO_CODES_SORTED = Object.keys(REGION_PRESETS).sort()

// Export REGION_PRESETS
export { REGION_PRESETS, ISO_CODES_SORTED }
