import { DifferenceIndicator } from 'components/DifferenceIndicator'
import s from './CellRenderer.module.scss'
import { numberFormatter } from '../../../utils/formatter'
import { Icon, RadixPopover } from 'components/Primitives'
import { FormattedMessage } from 'react-intl'
import { useRegionFormatPreset } from 'hooks/useRegionFormatPreset'

export const NumberWithDifferenceRenderer = (props) => {
  const { value, valueFormatted, data, colDef } = props
  const regionPreset = useRegionFormatPreset()
  if (value == null) return null

  const diff = data[`${colDef.field}_diff`]
  const locked = typeof (colDef.isLocked) === 'function' ? colDef.isLocked(data) : colDef.isLocked
  const oldValue = locked ? data.requested_amount : null

  const inner = (
    <>
      {diff != null && <DifferenceIndicator hideZeros size='xs' difference={diff} className='mr-2' formatter={(props) => numberFormatter({ ...props, colDef: { ...props.colDef, regionPreset } })} />}
      {valueFormatted}
      {locked && <Icon size='xs' name='User' className='ml-2' />}
    </>
  )
  return (
    <div className={s.flexRightCell}>
      {oldValue != null
        ? (
          <RadixPopover showOnHover title={<FormattedMessage id='Grid.calculatedOrderProposal' defaultMessage='Calculated order proposal: {value}' values={{ value: numberFormatter({ value: oldValue, colDef: { ...colDef, regionPreset } }) }} />}>
            {inner}
          </RadixPopover>
          )
        : inner}
    </div>
  )
}
