import { FlexibleScaledNumberFormat } from 'components/FlexibleScaledNumberFormat'
import { BellIcon } from '@heroicons/react/solid'
import { useRegionFormatPreset } from 'hooks/useRegionFormatPreset'

export const OpenMeasurementsIndicator = ({ className, count, tiny }) => {
  const regionPreset = useRegionFormatPreset()
  if (!count) return
  return (
    <div className={className}>
      <span className='text-right flex items-center justify-center h-5 min-w-1.5 px-2 text-sm font-bold text-white rounded-full shadow bg-feedback-error tracking-wide'>
        {tiny
          ? <BellIcon className='w-3 h-3' />
          : <FlexibleScaledNumberFormat
              value={count}
              decimalSeparator={regionPreset.decimalSeparator}
              thousandSeparator={regionPreset.thousandSeparator}
              decimalScale={2}
              displayType='text'
            />}
      </span>
    </div>
  )
}
