import { DefaultState } from 'components/Pickers/ComparisonPeriodPicker'
import { DEFAULT_FIELDS, Filter } from 'constants/index'
import { getRangePreset } from 'utils'

export const GRID_DEFAULT_SETTINGS = {
  pagination: {
    current: 1,
    pageSize: 50
  },
  colView: 'default',
  colDensity: 'regular',
  colStates: []
}

export const ORDER_CONFIG_DEFAULT_SETTINGS = {
  locations: [],
  location_tag: [],
  items: [],
  item_tag: [],
  dateRange: {
    value: [],
    option: null
  },
  weekdays: []
}

export const TODO_CONFIG_DEFAULT_SETTINGS = {
  locations: [],
  location_tag: [],
  items: [],
  item_tag: [],
  dateRange: {
    value: [],
    option: null
  },
  weekdays: []
}

export const CLIENT_SCHEDULES_DEFAULT_SETTINGS = {}

export const RECIPE_LINES_DEFAULT_SETTINGS = {
  locations: [],
  composites: [],
  components: []
}
export const EVENTS_DEFAULT_SETTINGS = {
  locations: [],
  category: [],
  items: [],
  search: '',
  dateRange: {
    value: [],
    option: null
  }
}
export const DASHBOARD_DEFAULT_FILTERS = {
  location: [],
  itemCategory1: [],
  itemCategory2: [],
  og1: [],
  og2: [],
  og3: [],
  itemName: [],
  dateRange: {
    value: getRangePreset('yesterday', true),
    option: 'yesterday'
  },
  weekdays: [],
  wasteCategory: [],
  [Filter.COMPARISON]: DefaultState
}
export const DASHBOARD_DEFAULT_SETTINGS = {
  refreshInterval: 'off'
}
export const FORECAST_DEFAULT_SETTINGS = {
  view: 'grid',
  location: null,
  itemCategory1: [],
  itemCategory2: [],
  og1: [],
  og2: [],
  itemName: [],
  dateRange: {
    value: getRangePreset('thisWeek', true),
    option: 'thisWeek'
  },
  fields: DEFAULT_FIELDS
}
export const FOODWASTE_DEFAULT_SETTINGS = {
  location: [],
  wasteCategory: [],
  status: null,
  dateRange: {
    value: getRangePreset('thisWeek', true),
    option: 'thisWeek'
  }
}
export const ORDERS_DEFAULT_SETTINGS = {
  location: [],
  dateRange: {
    value: getRangePreset('today', true),
    option: 'today'
  },
  item__category_1: [],
  item__category_2: [],
  history: null,
  item: []
}
export const TODO_DEFAULT_SETTINGS = {
  location: null,
  scale: 'sm'
}
export const CUSTOMERS_DEFAULT_SETTINGS = {
  search: undefined
}
export const ADMIN_DEFAULT_SETTINGS = {
  tab: 'manageUsers',
  foodwasteConfigs: {
    locations: [],
    users: [],
    waste_category: []
  },
  orderConfigs: ORDER_CONFIG_DEFAULT_SETTINGS,
  todoConfigs: TODO_CONFIG_DEFAULT_SETTINGS,
  recipeLines: RECIPE_LINES_DEFAULT_SETTINGS,
  clientSchedules: CLIENT_SCHEDULES_DEFAULT_SETTINGS,
  events: EVENTS_DEFAULT_SETTINGS,
  items: {
    todo_tags: null,
    orders_enabled: null,
    category_1: [],
    category_2: [],
    category_3: [],
    todo_config_category: [],
    order_config_category: [],
    search: undefined
  },
  locations: {
    forecast_enabled: null,
    todo_enabled: null,
    orders_enabled: null,
    search: undefined
  },
  users: {
    locations: [],
    accessRights: [],
    search: undefined
  },
  importedFiles: {
    locations: [],
    format_name: ''
  }
}
export const globalSettingsDefault = {
  sidebarCollapsed: false,
  showCustomer: true
}
export const CUSTOMER_DEFAULT_SETTINGS = {
  enableTracking: true,
  showLocationRemotePK: false,
  returnRatioField: '',
  dashboardPreview: false,
  forceDashboardPreview: false,
  region: 'de-DE'
}

export const USER_DEFAULT_ADMIN_SETTINGS = {
  dashboardPreview: false
}
