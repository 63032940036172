import classNames from 'classnames'
import s from './Text.module.scss'

/**
 * Having TypeScript would be really nice here...
 * type: headline | body | link | buttonLabel | table | caption | overline | display | filter
 * size: xs | s | m | l | xl | xxl
 * color: brand | white | black | gray | disabled-10 | disabled-20 | click | gray-neutral

* tailind line 3.25rem 2.5rem 2rem 1.75rem 1.5rem 1.25rem 1.125rem 1rem 0.75rem

 */

const validPropCombinations = {
  headline: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
  body: ['xs', 's', 'm'],
  link: ['xs', 's', 'm'],
  buttonLabel: ['xs', 's', 'm', 'l'],
  table: ['s', 'm'],
  caption: ['s', 'm'],
  overline: ['xs', 's'],
  display: ['s', 'm', 'l', 'xl', 'xxl'],
  filter: ['m']
}

export const Text = ({ id, onClick, children, tagName: Tag = 'span', color, className, type = 'body', size = 's', bold, innerRef, ...rest }) => {
  const isValid = (validPropCombinations[type] || []).includes(size)
  if (!isValid) throw new Error(`Invalid type/size combination ${type}/${size}`)

  return (
    <Tag
      ref={innerRef}
      {...rest}
      id={id}
      onClick={onClick}
      className={classNames(s[type], s[size], ['link', 'buttonLabel'].includes(type)
        ? null
        : {
            'text-inherit': color === 'inherit',
            'text-primary-darker': color === 'brand',
            'text-white': color === 'white',
            'text-gray': color === 'black',
            'text-gray-light': color === 'gray',
            'text-muted': color === 'disabled-10',
            'text-disabled': color === 'disabled-20',
            'text-primary-lighter': color === 'blue',
            'text-primary-lighter hover:text-primary-link': color === 'click',
            'text-feedback-error': color === 'danger',
            'text-gray-neutral': color === 'gray-neutral',
            'font-bold': bold
          },
      className)}
    >
      {children}
    </Tag>
  )
}
