import { JsonEditor } from 'json-edit-react'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import s from './Json.module.scss'
import { Button } from 'components/Button'
import PrettyTextarea from '@uiw/react-textarea-code-editor/nohighlight'
import { refractor } from 'refractor/lib/core.js'
import jsonLang from 'refractor/lang/json.js'
import rehypePrismGenerator from 'rehype-prism-plus/generator'
import { RenderCount } from 'components/RenderCount'

export const Json = ({ children, value, className, editable, allwaysEditing, editModeObserver, hasError, isValidObserver, onChange, editorHeight = 140 }) => {
  if (!!children && !!value) {
    console.warn(`Json component has both children and value props. value: ${value} will be ignored.`)
  }
  const usableValue = children || value
  const [editMode, setEditMode] = useState(false)
  const [textValue, setTextValue] = useState(JSON.stringify(usableValue, null, 2))
  const [validationError, setValidationError] = useState(null)

  const parsedJSON = useMemo(() => {
    try {
      const j = JSON.parse(textValue)
      setValidationError(null)
      return j
    } catch (ex) {
      setValidationError(ex.message)
      return undefined
    }
  }, [textValue])
  const isValid = parsedJSON !== undefined

  useEffect(() => {
    if (isValidObserver) isValidObserver(isValid)
    if (allwaysEditing) {
      if (isValid) onChange(parsedJSON)
      else onChange(textValue)
    }
  }, [textValue])

  useEffect(() => {
    if (editModeObserver) editModeObserver(editMode)
    if (!editMode && !allwaysEditing) {
      setTextValue(JSON.stringify(usableValue, null, 2))
    }
  }, [usableValue, editMode])

  const handleButtonClick = () => {
    if (!editMode) {
      setEditMode(true)
    } else {
      if (isValid) {
        onChange(parsedJSON)
      } else {
        setTextValue(JSON.stringify(usableValue, null, 2))
      }
      setEditMode(false)
    }
  }

  const renderPretty = () => (
    <JsonEditor
      className={classNames(s.pretty, (editMode || allwaysEditing) && 'hidden')} // recreating the component makes it forget the theme styles and looks funky, so it's best to just hide it
      data={children == null ? 'null' : children === 0 ? '0' : children}
      rootFontSize='0.75rem'
      theme={[
        'githubLight',
        {
          container: {
            padding: 0,
            marginTop: 0
          }
        }
      ]}
      restrictAdd
      restrictDelete
      restrictEdit
      maxWidth='100%'
    />
  )

  refractor.register(jsonLang)
  const rehypeJson = rehypePrismGenerator(refractor)
  const renderArea = () => (
    <div className={classNames(s.areaContainer, !editMode && !allwaysEditing && 'hidden')} style={{ height: editorHeight }}>
      <div>
        <PrettyTextarea
          value={textValue}
          language='json'
          onChange={(e) => setTextValue(e.target.value)}
          className={classNames(s.area, 'mono-font')}
          rehypePlugins={[[rehypeJson, { ignoreMissing: true }]]}
        />
      </div>
    </div>
  )

  return (
    <div className={classNames('relative rounded', (editMode || allwaysEditing) && 'pt-6 bg-code', hasError && 'border-solid border', className)}>
      <RenderCount />
      {editable && !allwaysEditing && (
        <Button
          className={classNames(s.button, editMode && s.editMode, editMode && !isValid && 'text-feedback-error')}
          type='tertiary'
          onClick={handleButtonClick}
          icon={editMode ? isValid ? 'Checkmark' : 'Error' : 'Edit'}
        />
      )}
      {(editMode || allwaysEditing) && (
        <div className={s.validator}>
          {isValid
            ? (
              <span className='text-feedback-success-tint'>
                {allwaysEditing
                  ? <FormattedMessage id='json.validMessageAlwaysEditing' defaultMessage='Valid JSON.' />
                  : <FormattedMessage id='json.validMessage' defaultMessage='Valid JSON. Please submit your changes using the ✓ button.' />}
              </span>
              )
            : <span className='text-feedback-error-lighter'>{validationError}</span>}
        </div>
      )}
      {renderPretty()}
      {renderArea()}
    </div>
  )
}
