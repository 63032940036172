import { NumericFormat } from 'react-number-format'

export const FlexibleScaledNumberFormat = ({ absolute, value, ...formatProps }) => {
  if (value == null) return null

  return (
    <NumericFormat
      value={value}
      {...formatProps}
    />
  )
}
