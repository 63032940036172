import * as RadixPopoverComponent from '@radix-ui/react-popover'
import classNames from 'classnames'
import { useState, useRef, useEffect } from 'react'
import s from '../Tooltip/Tooltip.module.scss' // Reuse the same styles as Tooltip

// A Popover component that can also behave like a Tooltip
export const RadixPopover = ({
  children,
  title,
  overlayClassName,
  side,
  triggerAsChild,
  open: controlledOpen,
  triggerClassName,
  modal, // Must be used if the popover is inside a modal
  showOnHover, // New prop to control tooltip-like behavior
  ...props
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false)
  const showTimeoutRef = useRef()
  const hideTimeoutRef = useRef()

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current)
      if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current)
    }
  }, [])

  const handleMouseEnter = () => {
    if (!showOnHover) return
    if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current)
    showTimeoutRef.current = setTimeout(() => setUncontrolledOpen(true), 300)
  }

  const handleMouseLeave = () => {
    if (!showOnHover) return
    if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current)
    hideTimeoutRef.current = setTimeout(() => setUncontrolledOpen(false), 500)
  }

  // Use controlled open state if provided, otherwise use internal state
  const open = controlledOpen !== undefined ? controlledOpen : uncontrolledOpen

  return (
    <RadixPopoverComponent.Root open={open} modal={modal} onOpenChange={setUncontrolledOpen}>
      <RadixPopoverComponent.Trigger
        className={classNames(s.trigger, triggerClassName)}
        asChild={triggerAsChild}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        {children}
      </RadixPopoverComponent.Trigger>
      <RadixPopoverComponent.Portal>
        <RadixPopoverComponent.Content
          side={side}
          className={classNames(s.root, overlayClassName, 'PopoverContent')}
          sideOffset={5}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {title}
          <RadixPopoverComponent.Arrow className={s.arrow} height={4} />
        </RadixPopoverComponent.Content>
      </RadixPopoverComponent.Portal>
    </RadixPopoverComponent.Root>
  )
}
