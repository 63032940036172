import { Icon, Popover, Text } from 'components/Primitives'
import { useEffect, useState } from 'react'
import { cronToFriendlyCron, getWeekdays } from 'utils/datetime'
import Heading from 'components/Heading'
import { Button } from 'components/Button'
import globalMessages from 'components/globalMessages'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import s from './CronPicker.module.scss'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { FilterFooter } from 'components/FilterFooter'
import { useIsMobile } from 'hooks'
import { TextSelect } from 'components/TextSelect'
import { addDays, format } from 'date-fns'
import labelMessages from 'components/labelMessages'
import messages from './messages'
import de from 'date-fns/locale/de'

export const cronToScheduleState = (values) => {
  const [cron, timezone] = values?.split(';') || []

  return {
    minutes: !cron || cron.split(' ')[0] === '*' ? ['0'] : cron.split(' ')[0].split(','),
    hours: !cron || cron.split(' ')[1] === '*' ? ['0'] : cron.split(' ')[1].split(','),
    weekdays: !cron || cron.split(' ')[4] === '*' ? [] : cron.split(' ')[4].split(','),
    timezone: timezone || 'Europe/Berlin'
  }
}

const scheduleStateToCron = (state) => {
  if (!state.minutes.length) return null
  return `${state.minutes} ${state.hours?.length ? state.hours : '*'} * * ${state.weekdays?.length ? state.weekdays : '*'};${state.timezone}`
}

export const calculateNextRun = (state) => {
  if (!state.minutes.length) return null

  let nextDate = new Date()
  const currentMinute = nextDate.getMinutes()
  let currentHour = nextDate.getHours()
  let currentWeekday = nextDate.getDay()

  const minutes = state.minutes.map((m) => parseInt(m)).sort((a, b) => a - b)
  const hours = state.hours.length
    ? state.hours.map((h) => parseInt(h)).sort((a, b) => a - b)
    : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  const weekdays = state.weekdays.length
    ? state.weekdays.map((w) => parseInt(w)).sort((a, b) => a - b)
    : [0, 1, 2, 3, 4, 5, 6]

  let nextMinute, nextHour, nextWeekday
  nextMinute = minutes.find((m) => m >= currentMinute)
  if (nextMinute === undefined) {
    nextMinute = minutes[0]
    currentHour = (currentHour + 1) % 24
    if (currentHour === 0) nextDate = addDays(nextDate, 1)
  }
  nextHour = hours.find((h) => h >= currentHour)
  if (nextHour === undefined) {
    nextMinute = minutes[0]
    nextHour = hours[0]
    currentWeekday = (currentWeekday + 1) % 7
    nextDate = addDays(nextDate, 1)
  }
  nextWeekday = weekdays.find((w) => w >= currentWeekday)
  if (nextWeekday === undefined) {
    nextMinute = minutes[0]
    nextHour = hours[0]
    nextWeekday = weekdays[0]
  }
  nextDate.setMinutes(nextMinute)
  nextDate.setHours(nextHour)
  nextDate = addDays(nextDate, (nextWeekday - nextDate.getDay() + 7) % 7)
  if (nextDate < new Date()) console.error('Next run is in the past')
  return nextDate
}

export const CronPicker = ({ value, onChange, disabled, placeholder, className, hasError, defaultOpen = false, noIcon, inModal, onOpenChange }) => {
  const intl = useIntl()
  const isMobile = useIsMobile()

  const [state, setState] = useState(cronToScheduleState(value))

  const [open, setOpen] = useState(defaultOpen)
  const [textSelectOpen, setTextSelectOpen] = useState(false)

  useEffect(() => {
    if (open) setState(cronToScheduleState(value))
    else setTextSelectOpen(false)
    if (onOpenChange) onOpenChange(open)
  }, [open])

  const reset = () => {
    onChange(null)
    setOpen(false)
  }

  const apply = () => {
    onChange(scheduleStateToCron(state))
    setOpen(false)
  }

  const isValid = !!state.minutes.length
  const nextRun = calculateNextRun(state)

  const renderContent = () => {
    return (
      <>
        <div>
          <div className='mb-4 flex justify-between'>
            <Heading type='h5'>{intl.formatMessage(labelMessages.schedule)}</Heading>
            <Button size='s' name='btn-reset' type='tertiary' className='px-0 bg-transparent' disabled={!value} onClick={reset}>{intl.formatMessage(globalMessages.reset)}</Button>
          </div>

          <Text color='black'>{intl.formatMessage(messages.minute)}</Text>
          <TextSelect
            className='mb-2'
            value={state.minutes}
            onChange={(v) => {
              setState({
                ...state,
                minutes: v
              })
            }}
            creatable={false}
            isMulti
            options={['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']}
            onOpenListener={setTextSelectOpen}
          />

          <Text color='black'>{intl.formatMessage(messages.hour)}</Text>
          <TextSelect
            className='mb-2'
            placeholder={intl.formatMessage(messages.everyHour)}
            value={state.hours}
            onChange={(v) => {
              setState({
                ...state,
                hours: v
              })
            }}
            creatable={false}
            isMulti
            options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']}
            onOpenListener={setTextSelectOpen}
          />

          <Text color='black'>{intl.formatMessage(labelMessages.weekday)}</Text>
          <TextSelect
            className='mb-2'
            placeholder={intl.formatMessage(messages.everyWeekday)}
            value={state.weekdays}
            onChange={(v) => {
              setState({
                ...state,
                weekdays: v
              })
            }}
            creatable={false}
            isMulti
            options={getWeekdays(intl.locale)}
            onOpenListener={setTextSelectOpen}
          />

          <div className='flex flex-col'>
            <Text type='filter' size='m' className={classNames('text-center', !isValid && 'text-feedback-error')}>
              {isValid
                ? intl.formatMessage(messages.nextRun, { date: format(nextRun, 'EEEEEE, P p', { locale: intl.locale === 'de-DE' ? de : undefined }) })
                : intl.formatMessage(messages.selectMinutes)}
            </Text>
          </div>
        </div>
      </>
    )
  }

  const onCancelButtonClick = () => {
    setOpen(false)
  }

  const renderTrigger = () => {
    return (
      <div
        className={classNames(
          s.wrapper,
          {
            [s.opened]: open,
            [s.hasValue]: !!value && !disabled,
            [s.hasError]: hasError
          },
          className
        )}
        disabled={disabled}
        tabIndex={-1}
        onClick={() => setOpen(!open)}
      >
        {!noIcon && (
          <div className={classNames(s.prefixWrapper, { [s.hasValue]: !!value && !disabled })}>
            <Icon name='EventSchedule' />
          </div>
        )}
        <Text color={!!value && !disabled && !noIcon ? 'black' : 'gray'}>
          {open
            ? cronToFriendlyCron(scheduleStateToCron(state)) || placeholder
            : value ? cronToFriendlyCron(value) : placeholder}
        </Text>
      </div>
    )
  }

  if (isMobile) {
    return (
      <>
        {renderTrigger()}
        {open && (
          <MobileFriendlyModal
            visible={open}
            title={intl.formatMessage(labelMessages.schedule)}
            onCancel={onCancelButtonClick}
            onPrimary={apply}
            footer={{
              primaryText: intl.formatMessage(globalMessages.ok)
            }}
          >
            {renderContent()}
          </MobileFriendlyModal>
        )}
      </>
    )
  }
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger={renderTrigger()}
      inModal={inModal}
    >
      {(maxHeight) => (
        <div className={classNames('flex flex-col p-4 w-96', !textSelectOpen && 'overflow-auto')} style={{ maxHeight }}>
          {renderContent()}
          <FilterFooter onCancel={onCancelButtonClick} onSubmit={apply} submitDisabled={!isValid} />
        </div>
      )}
    </Popover>
  )
}
