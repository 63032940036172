import { formatMoney, formatNumber } from 'accounting'
import { parseISO, format, parse, formatISO } from 'date-fns'
import { cronToFriendlyCron, formatLocalized } from './datetime'
import { DFNS_WEEK_FORMAT } from 'constants/index'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import { REGION_PRESETS } from 'constants/internationalization'

const getRegionPreset = (props) => {
  // Support both old colDef.regionPreset and new direct regionPreset
  const preset = props?.regionPreset || props?.colDef?.regionPreset || REGION_PRESETS['de-DE']

  if (!props?.regionPreset && !props?.colDef?.regionPreset) {
    console.warn('No regionPreset provided, falling back to de-DE. Please provide regionPreset in the format options.', new Error().stack)
  }

  return {
    decimal: preset.decimalSeparator,
    thousand: preset.thousandSeparator,
    format: preset.currencyPosition === 'prefix'
      ? `%s${preset.currencySeparator}%v`
      : `%v${preset.currencySeparator}%s`,
    currencySeparator: preset.currencySeparator
  }
}

export const numberFormatter = ({ value, colDef, regionPreset }, symbol = '') => {
  if (value == null) return '-'

  const format = getRegionPreset({ regionPreset, colDef })
  const precision = colDef && colDef.precision != null
    ? colDef.precision
    : (colDef && colDef.flexibleDecimals && value % 1 === 0)
        ? 0
        : 2

  if (colDef && colDef.minimize && Math.abs(value) > 1000000) {
    return formatMoney(value / 1000000, {
      decimal: format.decimal,
      thousand: format.thousand,
      precision: 1,
      symbol: `${colDef.minimize}${symbol ? format.currencySeparator : ''}${symbol}`,
      format: format.format
    })
  }

  return formatMoney(value, {
    decimal: format.decimal,
    thousand: format.thousand,
    precision,
    symbol: symbol || '',
    format: symbol ? format.format : '%v'
  })
}

export const baseNumberFormatter = ({ value, minimize, colDef, regionPreset }) => {
  if (value == null) return '-'

  const format = getRegionPreset({ regionPreset, colDef })

  if (minimize && Math.abs(value) > 1000000) {
    return formatMoney(value / 1000000, {
      decimal: format.decimal,
      thousand: format.thousand,
      precision: 1,
      symbol: minimize,
      format: '%v%s'
    })
  }

  return formatNumber(value, {
    decimal: format.decimal,
    thousand: format.thousand,
    precision: 0
  })
}

export const currencyFormatter = (props) => {
  const preset = props?.regionPreset || props?.colDef?.regionPreset
  return numberFormatter(props, preset?.currencySymbol)
}

export const percentFormatter = (props) => {
  if (props.value == null) return '-'

  const format = getRegionPreset(props)
  const number = formatNumber(props.value * 100, {
    decimal: format.decimal,
    thousand: format.thousand,
    precision: 2
  })
  return `${number} %`
}

export const cronFormatter = (props) =>
  cronToFriendlyCron(props.value)

export const kgFormatter = (props) => {
  if (props.value == null) return '-'
  const format = getRegionPreset(props)
  const precision = props.colDef?.precision != null ? props.colDef.precision : 2
  const value = formatNumber(props.value, {
    decimal: format.decimal,
    thousand: format.thousand,
    precision: precision
  })
  return `${value} kg`
}

export const gramFormatter = (props) => {
  if (props.value == null) return '-'
  const format = getRegionPreset(props)
  const precision = props.colDef?.precision != null ? props.colDef.precision : 2
  const value = formatNumber(props.value, {
    decimal: format.decimal,
    thousand: format.thousand,
    precision: precision
  })
  return `${value} g`
}

export const liquidFormatter = (props) => {
  if (props.value == null) return '-'
  const format = getRegionPreset(props)
  const precision = props.colDef?.precision != null ? props.colDef.precision : 2
  const value = formatNumber(props.value, {
    decimal: format.decimal,
    thousand: format.thousand,
    precision: precision
  })
  return `${value} l`
}

export const dateFormatter = (props) => {
  if (props.value == null) return null

  // if value is already date / number:
  const value = typeof props.value === 'string' ? props.value : formatISO(new Date(props.value), { representation: 'date' })

  if (value.includes('-W')) {
    // this is a week date
    return format(parse(value, DFNS_WEEK_FORMAT, new Date()), `'${props.colDef.intl.formatMessage(globalMessages.weekNumber)}' I | dd.MM.yyyy`)
  }

  if (props.colDef.enableTimeOnly && value.includes('T')) {
    // this is a time
    return format(parseISO(value), 'HH:mm')
  }

  return formatLocalized(parseISO(value), 'P', props.colDef.intl)
}

// Only supports values in HH:mm(:ss)? format for now
export const timeRangeFormatter = ({ data, colDef }) => {
  if (!data[colDef.fromField] || !data[colDef.toField]) return null
  return `${data[colDef.fromField].split(':').slice(0, 2).join(':')} - ${data[colDef.toField].split(':').slice(0, 2).join(':')}`
}

export const dateTimeFormatter = (props) => {
  if (props.value == null) return null
  const isoDate = typeof props.value === 'string' ? parseISO(props.value) : props.value
  return formatLocalized(isoDate, 'P p', props.colDef.intl)
}

export const deviationFormatter = ({ colDef, varianceField, value, data, regionPreset }) => {
  if (value == null) return null
  const format = getRegionPreset({ regionPreset, colDef })
  const vField = colDef && colDef.varianceField ? colDef.varianceField : varianceField || 'variance'

  return `${formatNumber(value, {
    decimal: format.decimal,
    thousand: format.thousand,
    precision: colDef && colDef.precision != null ? colDef.precision : 2
  })} ±${formatNumber(data[vField], {
    decimal: format.decimal,
    thousand: format.thousand,
    precision: 0,
    symbol: colDef && colDef.suffix
  })}`
}

export const daysFormatter = ({ value, colDef }) => {
  if (value === null || value === undefined) return ''
  if (typeof (value) === 'number') return `${value} ${colDef.intl.formatMessage(labelMessages.days, { count: value })}`
  return ''
}

const textFormatter = ({ value }) => value
export const getFormatterForType = (returnType) => {
  switch (returnType) {
    case 'text':
    default:
      return textFormatter
    case 'number':
      return numberFormatter
    case 'integer':
      return baseNumberFormatter
    case 'currency':
      return currencyFormatter
    case 'percent':
      return percentFormatter
    case 'cron':
      return cronFormatter
    case 'weight':
    case 'kg':
      return kgFormatter
    case 'gram':
      return gramFormatter
    case 'liquid':
      return liquidFormatter
    case 'date':
      return dateFormatter
    case 'timeRange':
      return timeRangeFormatter
    case 'deviation':
      return deviationFormatter
    case 'days':
      return daysFormatter
  }
}

export const formatWith = (value, formatter, intl, regionPreset) => formatter({ value, regionPreset, colDef: { intl } })
