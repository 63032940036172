import { omit } from 'lodash'
import { create } from 'zustand'

import { track } from '@amplitude/analytics-browser'
import { setAmplitudeVars, useAmplitudeStore } from './useAmplitudeStore'

const TRACK_MODALS = {
  addReportModal: {
    name: 'Add Report Modal',
    measure: true
  },
  dashboardSettings: {
    name: 'Dashboard Settings'
  }
}

export const trackModalOpened = (modalName, data) => {
  const tm = TRACK_MODALS[modalName]
  if (tm && useAmplitudeStore.getState().enabled) {
    console.log('🔎 ~ Track Modal Opened', tm, data)
    if (tm.measure) {
      setAmplitudeVars({ started: performance.now() })
    }
    track(`${tm.name} opened`, null)
  }
}

export const useModalStore = create((set) => ({
  modals: {},
  show: (modalName, data) => {
    trackModalOpened(modalName, data)
    return set((state) => ({ modals: { ...state.modals, [modalName]: data || true } }))
  },
  hide: (modalName) => set((state) => ({ modals: omit(state.modals, [modalName]) }))
}))
