import { useEffect, useMemo } from 'react'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useKeyPress, useMount, useNetwork } from 'ahooks'
import { OPERATOR, ADMIN, STUDENT_UNION, BUSINESS } from 'constants/index'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { isEqual, find } from 'lodash'
import { useAdminData, useLocations, useUser } from 'hooks'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { setSetting } from 'services/SettingsProvider'
import { useCustomerType } from 'hooks/useCustomerType'

export const INITIAL_PERMISSIONS = {
  isInitialized: false,
  isSuperUser: false,
  isOperator: false,
  isAdmin: false,
  foodwaste: false,
  offering: false,
  safetySettings: false,
  todo: false
}

export const Root = () => {
  const logDebugMessage = useDebugStore((state) => state.logDebugMessage)
  const networkState = useNetwork()
  const toggle = useDebugStore((state) => state.toggle)
  const storePermissions = useGeneralStore((state) => state.permissions)
  const setPermissions = useGeneralStore((state) => state.setPermissions)
  const settings = useSettingsStore(state => state.settings)

  const { user } = useUser()
  const customerType = useCustomerType()
  const { data: allConfigData } = useAdminData('foodwasteConfigs', {
    enabled: user != null && [OPERATOR, ADMIN].includes(user.role),
    filters: {
      pagination: {
        pageSize: 1
      }
    }
  })

  const { data: locations } = useLocations()

  useEffect(() => {
    window.getUserSettings = () => settings
  }, [settings])

  useMount(() => {
    logDebugMessage('This is an initial site load.')
    window.setUserSettings = (settings) => setSetting(settings)
    window.debugConsole = () => toggle()
  })
  useEffect(() => logDebugMessage(`Online State: ${networkState.online}`), [networkState.online])

  useKeyPress((e) => ['Dead', '^', '`', '§'].includes(e.key) && (e.ctrlKey || e.metaKey), () => {
    toggle()
  })

  const permissions = useMemo(() => {
    if (!user || !locations || ([OPERATOR, ADMIN].includes(user.role) && allConfigData.items == null)) {
      return INITIAL_PERMISSIONS
    }

    const isSuperUser = [OPERATOR, ADMIN].includes(user.role)
    return {
      isInitialized: true,
      isSuperUser: isSuperUser,
      isOperator: user.role === OPERATOR,
      isAdmin: user.role === ADMIN,
      // user is superuser or has explicit permission
      foodwaste: isSuperUser ? (allConfigData != null && allConfigData.count > 0) : [STUDENT_UNION, BUSINESS].includes(customerType) && user.food_waste_access === true,
      // user is superuser or has explicit permission
      offering: isSuperUser || user.offering_view_access === true,
      // user is superuser or has explicit permission
      safetySettings: isSuperUser || user.safety_setting_rights === true,
      // user has access to any location with todo enabled
      todo: find(locations, { todo_enabled: true }) != null
    }
  }, [user, locations, allConfigData])

  useEffect(() => {
    if (!isEqual(permissions, storePermissions)) {
      setPermissions(permissions)
    }
  }, [permissions])

  return null
}
