import { Badge } from 'components/Primitives'
import { useRegionFormatPreset } from 'hooks/useRegionFormatPreset'
import { baseNumberFormatter } from 'utils/formatter'

export const DifferenceIndicator = ({ difference, inverted, size, formatter, className, hideZeros }) => {
  const regionPreset = useRegionFormatPreset()
  if (difference === 0 && hideZeros) return null
  const isPositive = difference > 0

  let type, icon, sign
  if (difference === 0) {
    type = 'accentNeutral'
    icon = 'ArrowRight'
  } else if (isPositive) {
    type = inverted ? 'accentPeachrose' : 'accentGras'
    sign = '+'
  } else {
    type = inverted ? 'accentGras' : 'accentPeachrose'
    sign = '-'
  }

  return (
    <Badge light size={size} type={type} icon={icon} className={className} data-cy='delta-badge' data-cy-diff={difference}>
      {sign}
      {formatter
        ? formatter({
            value: Math.abs(difference),
            colDef: {
              flexibleDecimals: true,
              regionPreset
            }
          })
        : baseNumberFormatter({ value: Math.abs(difference), regionPreset })}
    </Badge>
  )
}
