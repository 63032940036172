import classNames from 'classnames'
import s from './History.module.scss'
import { Text, Tooltip } from 'components/Primitives'
import { FormattedMessage, useIntl } from 'react-intl'
import { DateValue } from 'components/DateValue'
import { formatRelative } from 'utils/datetime'
import { getUserName } from 'utils'
import { NumericFormat } from 'react-number-format'
import { useDemo } from 'hooks'
import { Json } from 'components/Json'
import labelMessages from 'components/labelMessages'
import titleMessages from 'components/titleMessages'
import { baseNumberFormatter, deviationFormatter, kgFormatter } from 'utils/formatter'
import { DifferenceIndicator } from 'components/DifferenceIndicator'
import { useRegionFormatPreset } from 'hooks/useRegionFormatPreset'

const Dot = () => (
  <div className={s.dot}>
    <div />
  </div>)

const Spacer = ({ idx, total }) => (
  <div
    className={classNames(
      idx === total - 1 ? 'h-6' : '-bottom-6',
      s.spacer
    )}
  >
    <div className='w-px bg-gray-border' />
  </div>
)

export const History = ({ data, type, diffField, diffInverted }) => {
  const intl = useIntl()
  const { demo } = useDemo()
  const regionPreset = useRegionFormatPreset()

  if (!data) return null

  const renderText = (item, idx) => {
    switch (type) {
      case 'waste':
        return (
          <Text className='flex-auto'>
            <span className='mr-1 font-bold'>{
        item.user_email
          ? demo(getUserName({
              email: item.user_email,
              last_name: item.user_last_name,
              first_name: item.user_first_name
            }))
          : 'Delicious Data'
      }
            </span>
            {idx === 0
              ? <FormattedMessage id='History.createdMeasurement' defaultMessage='created the measurement.' />
              : item.amount != null
                ? <FormattedMessage
                    id='History.updatedMeasurement'
                    defaultMessage='updated the measurement with <b>{value}</b>.'
                    values={{
                      value: (
                        <NumericFormat
                          value={item.amount}
                          decimalSeparator={regionPreset.decimalSeparator}
                          thousandSeparator={regionPreset.thousandSeparator}
                          suffix=' kg'
                          decimalScale={2}
                          displayType='text'
                          fixedDecimalScale
                        />
                      ),
                      b: chunks => <b>{chunks}</b>
                    }}
                  />
                : <FormattedMessage
                    id='History.deletedMeasurement'
                    defaultMessage='has reset the measurement.'
                  />}
          </Text>
        )
      case 'forecast':
        const forecastText = (
          <Text tagName='p' className='flex-auto'>
            <span className='mr-1 font-bold'>
              <FormattedMessage {...titleMessages.forecast} />
            </span>
            <FormattedMessage
              id='History.updatedValue'
              defaultMessage='has been set to <b>{value}</b>.'
              values={{ value: deviationFormatter({ varianceField: 'forecast_variance', value: item.forecast, data: item }), b: chunks => <b>{chunks}</b> }}
            />
          </Text>
        )
        if (item.num_planned == null) return forecastText
        return (
          <div className='flex-auto'>
            {forecastText}
            <Text tagName='p'>
              <span className='mr-1 font-bold'>
                <FormattedMessage {...labelMessages.planned} />
              </span>
              <FormattedMessage
                id='History.updatedValue'
                defaultMessage='has been set to <b>{value}</b>.'
                values={{
                  value: (
                    <NumericFormat
                      value={item.num_planned}
                      decimalSeparator={regionPreset.decimalSeparator}
                      thousandSeparator={regionPreset.thousandSeparator}
                      decimalScale={2}
                      displayType='text'
                    />
                  ),
                  b: chunks => <b>{chunks}</b>
                }}
              />
            </Text>
          </div>

        )
      default:
        return (
          <Text className='flex-auto'>
            Unknown type: {type}
            <Json>{item}</Json>
          </Text>
        )
    }
  }

  const getFormatter = (type) => {
    switch (type) {
      case 'waste':
        return kgFormatter
      case 'forecast':
        return baseNumberFormatter
      default:
        return null
    }
  }

  return (
    <ul role='list' className='pl-0 space-y-6'>
      {data.map((item, idx) => {
        const prevItem = idx > 0 ? data[idx - 1] : null
        const date = type === 'waste' ? item.created_at : item.updated_at || item.created_at
        return (
          <li key={item.id} className='flex relative gap-x-4 items-center'>
            <Spacer idx={idx} total={data.length} />
            <Dot />
            {diffField && prevItem && prevItem[diffField] != null && item[diffField] != null && (
              <div className={s.diff}>
                <DifferenceIndicator
                  size='xs'
                  inverted={diffInverted}
                  difference={item[diffField] - prevItem[diffField]}
                  formatter={getFormatter(type)}
                />
              </div>)}
            {renderText(item, idx)}
            <div className='flex items-center'>
              {(date) && (
                <Tooltip side='left' title={<DateValue iso withTime asString>{date}</DateValue>}>
                  <Text size='xs'>
                    {formatRelative(new Date(date), intl)}
                  </Text>
                </Tooltip>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
