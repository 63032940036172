import { cloneDeep, filter, findIndex, forEach, isEqual, omit, pick } from 'lodash'
import { handleMutationError, isNullOrEmptyArray, normalizeLocations } from 'utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import message from 'services/message'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryFetcher, useSelectedCustomer } from 'hooks'
import { Filter } from 'constants/index'

const joinedArrayParams = ['locations', 'users', 'items', 'categories', 'category', 'waste_category', 'accessRights', 'todo_tags', 'todo_config_category', 'order_config_category', 'locationTags', 'tags', 'composites', 'components', 'weekdays']
const defaultParams = ['page', 'pageSize', 'pagination']
const defaultParamsMapping = {
  pageSize: 'page_size'
}

const extractFilters = (filters, params, defaultOrder = null, mapping = {}) => {
  if (!filters) { return {} }
  const extracted = {}
  const paramsMap = {
    ...defaultParamsMapping,
    ...mapping
  }
  const mapKeys = Object.keys(paramsMap)

  forEach([...defaultParams, ...params], p => {
    const key = mapKeys.includes(p) ? paramsMap[p] : p
    let val
    if (joinedArrayParams.includes(p)) {
      val = isNullOrEmptyArray(filters[p]) ? undefined : Array.isArray(filters[p]) ? filters[p].join(',') : filters[p]
    } else if (p === 'range') {
      const range = filters[Filter.DATE_RANGE]?.value
      if (range && range.length === 2) {
        extracted.from_date = range[0]
        extracted.to_date = range[1]
      }
    } else if (p === 'pagination' && filters[p] != null) {
      extracted.page = filters[p].current || 1
      extracted.page_size = filters[p].pageSize || 25
    } else if (p === 'ordering' && defaultOrder !== null && filters[p] != null) {
      const orderingArr = filters[p].split(',')
      const neutralizedOrderingArr = orderingArr.map((o) => o.replace('-', ''))
      const defaultOrderArr = defaultOrder.split(',')

      defaultOrderArr.forEach((o) => {
        if (!neutralizedOrderingArr.includes(o)) {
          orderingArr.push(o)
        }
      })

      val = orderingArr.join(',')
    } else {
      val = filters[p]
    }
    if (val != null) {
      if (Array.isArray(val)) {
        extracted[`${key}[]`] = val
      } else {
        extracted[key] = val
      }
    }
  })

  return extracted
}

const getOpts = (options) => ({
  enabled: true,
  filters: {},
  excludeFromQueryKey: [],
  defaultOrder: null,
  ordering: null,
  skipOnSettled: false,
  ...options
})

export const useAdminData = (type = 'users', options) => {
  const [opts, setOpts] = useState(getOpts(options))

  useEffect(() => {
    const newOpts = getOpts(options)
    if (isEqual(newOpts, opts)) return
    setOpts(newOpts)
  }, [options])

  const queryClient = useQueryClient()
  const { fetch, token } = useQueryFetcher()
  const selectedCustomer = useSelectedCustomer()

  let route

  const filters = useMemo(() => {
    switch (type) {
      case 'foodwasteConfigs':
        return {
          ...extractFilters(opts.filters, ['ordering', 'locations', 'users', 'waste_category', 'is_active'], opts.defaultOrder, { locations: 'sales_locations', waste_category: 'categories' }),
          ordering: opts.ordering
        }
      case 'locations':
        return {
          ...extractFilters(opts.filters, ['forecast_enabled', 'todo_enabled', 'orders_enabled', 'search', 'locationTags'], opts.defaultOrder, { locationTags: 'tags' }),
          ordering: opts.ordering
        }
      case 'tags':
        if (!opts.type) throw new Error('When using useAdminData for tags, you must specify the type')
        return { ordering: 'name', page_size: 9999, page: 1, type: opts.type, remove_orphans: opts.remove_orphans }
      case 'mecos':
        return extractFilters(opts.filters, ['forecasts', 'locations', 'search'], opts.defaultOrder, { locations: 'sales_locations' })
      case 'geo-regions':
        return extractFilters(opts.filters, ['ordering', 'zip_codes'], opts.defaultOrder)
      case 'items':
        return {
          ...extractFilters(opts.filters, ['ordering', 'todo_tags', 'orders_enabled', 'category_1', 'category_2', 'category_3', 'tags', 'search'], opts.defaultOrder),
          ordering: opts.ordering
        }
      case 'customers':
        return extractFilters(opts.filters, ['ordering', 'search'], opts.defaultOrder)
      case 'order-configs':
        return extractFilters(opts.filters, ['ordering', 'locations', 'item_tag', 'location_tag', 'range', 'items', 'weekdays'], opts.defaultOrder)
      case 'todo-configs':
        return extractFilters(opts.filters, ['ordering', 'locations', 'item_tag', 'location_tag', 'range', 'items', 'weekdays'], opts.defaultOrder)
      case 'recipe-lines':
        return extractFilters(opts.filters, ['ordering', 'locations', 'components', 'composites', 'search'], opts.defaultOrder)
      case 'events':
        return {
          ...extractFilters(opts.filters, ['ordering', 'locations', 'items', 'category', 'date_range', 'search', 'range'], opts.defaultOrder),
          ordering: opts.ordering
        }
      case 'orders':
        return opts.filters // the filters are already prepared by useQueryFilter hook
      case 'imported-files':
        return {
          ...extractFilters(opts.filters, ['ordering', 'locations', 'format_name'], opts.defaultOrder),
          ordering: opts.ordering
        }
      case 'users':
        return {
          ...extractFilters(opts.filters, ['locations', 'accessRights', 'search', 'tags'], opts.defaultOrder, { accessRights: 'permissions', locations: 'assigned_locations' }),
          ordering: opts.ordering
        }
      case 'client-request-schedules':
        return {
          ...extractFilters(opts.filters, ['ordering', 'search'], opts.defaultOrder),
          ordering: opts.ordering
        }
      case 'last-client-requests':
        return {
          ...extractFilters(opts.filters, ['schedules'])
        }
    }
  }, [opts.filters, opts.ordering, opts.defaultOrder, opts.removeOrphans, type])

  const queryKey = useMemo(() => {
    switch (type) {
      case 'foodwasteConfigs':
        return ['admin-foodwaste-configs', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'locations':
        return ['admin-locations', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'tags':
        return ['tags', opts.type, opts.remove_orphans, { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'mecos':
        return ['mecos', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'geo-regions':
        return ['geo-regions', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'items':
        return ['admin-items', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'customers':
        return ['operator-customers', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'order-configs':
        return ['order-configs', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'todo-configs':
        return ['todo-configs', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'recipe-lines':
        return ['recipe-lines', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'events':
        return ['events', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'orders':
        return ['orders', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'imported-files':
        return ['sales-data-files', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'users':
        return ['admin-users', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'client-request-schedules':
        return ['client-request-schedules', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      case 'last-client-requests':
        return ['last-client-requests', { selectedCustomer, filters: omit(filters, opts.excludeFromQueryKey) }]
      default:
        throw new Error(`Invalid type: ${type}`)
    }
  }, [selectedCustomer, filters])

  const invalidate = useCallback(() => queryClient.invalidateQueries({ queryKey: [queryKey[0]] }), [queryKey])

  switch (type) {
    case 'foodwasteConfigs':
      route = 'food-waste/configs'
      break
    case 'locations':
      route = 'sales-locations'
      break
    case 'tags':
      route = 'tags'
      break
    case 'mecos':
      route = 'mecos'
      break
    case 'geo-regions':
      route = 'geo-regions'
      break
    case 'items':
      route = 'items'
      break
    case 'customers':
      route = 'customers'
      break
    case 'order-configs':
      route = 'order-configs'
      break
    case 'todo-configs':
      route = 'todo-configs'
      break
    case 'recipe-lines':
      route = 'recipe-associations'
      break
    case 'events':
      route = 'events'
      break
    case 'orders':
      route = 'orders'
      break
    case 'imported-files':
      route = 'sales-data-files'
      break
    case 'client-request-schedules':
      route = 'clients/request-schedules'
      break
    case 'last-client-requests':
      route = 'clients/last-requests'
      break
    case 'users':
    default:
      route = 'users'
      break
  }

  const { data, status, error, isFetching, isLoading, refetch, remove } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/${route}/?${queryString.stringify({
          customer: selectedCustomer,
          ...filters
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: opts.enabled && selectedCustomer != null,
    retry: 2,
    staleTime: 120000
  })

  useEffect(() => {
    if (error) {
      if (typeof (error) === 'string') {
        message.error(error.split('\n')[0])
      } else {
        message.error('Error fetching data.')
      }
    }
  })

  const { mutateAsync: addMutation, isPending: addIsPending } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/${route}/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'POST',
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject),
          body: {
            ...data,
            ...(data.locations ? { locations: normalizeLocations(data.locations) } : undefined)
          }
        }
      )
    }),
    onSettled: !opts.skipOnSettled ? () => queryClient.invalidateQueries({ queryKey: [queryKey[0]] }) : undefined
  })

  const { mutateAsync: updateMutation, isPending: updateIsPending } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/${route}/${data.id}/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'PATCH',
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject),
          body: {
            ...(type === 'orders' ? omit(data, ['id']) : data),
            ...(data.locations ? { locations: normalizeLocations(data.locations) } : undefined)
          }
        }
      )
    }),
    // We use optimistic updating to simulate the new data before we have it
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey })
      const previousData = queryClient.getQueryData(queryKey)
      const newData = previousData ? cloneDeep(previousData) : {}

      const index = findIndex(newData.results, type === 'orders' ? { history_id: data.id } : { id: data.id })

      if (index !== -1) {
        if (type === 'orders') {
          const newObj = {
            ...newData.results[index],
            ...data,
            effective_requested_amount: data.override_requested_amount != null ? data.override_requested_amount : newData.results[index].requested_amount
          }
          newData.results[index] = newObj
        } else {
          newData.results[index] = {
            ...newData.results[index],
            ...data
          }
        }
      }
      queryClient.setQueryData(queryKey, newData)
      return { previousData, index }
    },
    onError: (_err, _newData, context) => queryClient.setQueryData(queryKey, context.previousData),
    onSettled: !opts.skipOnSettled
      ? () => queryClient.invalidateQueries({ queryKey: [queryKey[0]] })
      : (resp, _a, _params, context) => {
          if (type === 'orders') {
            const newData = { ...context.previousData }
            if (context.index !== -1) {
              newData.results[context.index] = {
                ...newData.results[context.index],
                ...pick(resp, ['effective_requested_amount', 'override_requested_amount'])
              }
              queryClient.setQueryData(queryKey, newData)
            }
          }
        }
  })

  const { mutateAsync: removeMutation, isPending: removeIsPending } = useMutation({
    mutationFn: (id) => new Promise((resolve, reject) => {
      fetch(
        `/${route}/${id}/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'DELETE',
          token,
          success: (res) => resolve(res.results),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey })
      const previousData = queryClient.getQueryData(queryKey)
      if (previousData) {
        const results = filter(previousData.results, (i) => i.id !== id)
        const newData = {
          ...previousData,
          results
        }
        queryClient.setQueryData(queryKey, newData)
        return { previousData }
      }
    },
    onError: (_err, newData, context) => {
      if (context) {
        queryClient.setQueryData(queryKey, context.previousData)
      }
    },
    onSettled: !opts.skipOnSettled ? () => queryClient.invalidateQueries({ queryKey: [queryKey[0]] }) : undefined
  })

  // FIXME: users bulk update endpoint does not support updating locations yet. Eric will implement it.
  const { mutateAsync: bulkMutation, isPending: bulkIsPending } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/${route}/bulk-update/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'PATCH',
          token,
          success: (res) => {
            resolve(res)
          },
          failure: (errors) => handleMutationError(errors, reject),
          body: data
        }
      )
    }),
    // We use optimistic updating to simulate the new data before we have it
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey })
      const previousData = queryClient.getQueryData(queryKey)
      const newData = { ...previousData }
      const ids = data.map(i => i.id)
      const indexes = ids.map(id => findIndex(newData.results, { id })).filter(i => i !== -1)

      indexes.forEach(idx => {
        newData.results[idx] = {
          ...newData.results[idx],
          ...data
        }
      })

      queryClient.setQueryData(queryKey, newData)
      return { previousData }
    },
    onError: (_err, newData, context) => {
      queryClient.setQueryData(queryKey, context.previousData)
    },
    onSettled: !opts.skipOnSettled ? () => queryClient.invalidateQueries({ queryKey: [queryKey[0]] }) : undefined
  })

  const resultData = useMemo(() => {
    return {
      items: data ? data.results : null,
      count: data ? data.count : null,
      status,
      error,
      isFetching,
      isLoading,
      refetch,
      invalidate,
      remove
    }
  }, [data, status, error, isFetching, isLoading, refetch, invalidate, remove])

  return {
    data: resultData,
    add: {
      mutateAsync: addMutation,
      isPending: addIsPending
    },
    update: {
      mutateAsync: updateMutation,
      isPending: updateIsPending
    },
    bulkUpdate: {
      mutateAsync: bulkMutation,
      isPending: bulkIsPending
    },
    remove: {
      mutateAsync: removeMutation,
      isPending: removeIsPending
    }
  }
}
